<template>
  <div>
     <b-modal id="openSerList" class="modal-header" :title="data.provider_name?data.provider_name +'-'+ data.title:'Transactions List'" ok-only scrollable size="xl">

         <transcations-table :trxid="trxid" :netType="netType" :edate="edate" :sdate="sdate"></transcations-table>
      
     </b-modal>
  </div>
</template>
<script>
// Charts
import TranscationsTable from './manager/Transcations/TranscationsTable.vue'

export default {
  components: {
    TranscationsTable,
  },
  data() {
    return {
      trxid: '',
      netType:0,
      data: {},
      sdate: '',
      edate: ''
    };
  },
  methods: {  
  },
  mounted() {
     this.$root.$on("showtrxforlist", (data) => {
         this.data = data;
         if(data._id){this.trxid = data._id};   
         if(data.sdate){this.sdate = data.sdate};
         if(data.edate){this.edate = data.edate};
         if(data.netType){this.netType = data.netType};
    });
  },
};
</script>
<style scoped>
.modal-header {
 color: #1d75bc;
}
.text-blue {
  color: #1d75bc;
}
.table .acClass {
  background-color: red;
}
.table .bTableThStyle {
  background-color: blue !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  /* text-justify: auto; */
  width: 1000px;
  /* white-space: nowrap; */
  overflow: auto;
  text-overflow: ellipsis;
}
.modal-dialog-1 {
  position: fixed;
  margin: auto;

  height: 100%;
  right: 0px;
}
.modal-content-1 {
  height: 100%;
}
/* .el-table .cell {
  max-width: 150px;
  padding-left: 0px;
  padding-right: 0px;
} */
</style>

import api from './api'
import globalLit from './globalLit'
export default {

  chains(id) {
    return api.execute('get', `/chain/${id}`)
  },
  lists(data){
    return api.execute(`get`,`/lists`)
  },

  networkServices(data){
   let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/service/networks`, payload)
  },

  operatorPer(data) {
    let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/stats/networks`, payload)
  },
  categoryStat(data) {
    let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/stats/category`, payload)
  },

  apkList(data) {
    let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/list/apk`, payload)
  },
  insightsList(data) {
    let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/list/insights`, payload)
  },
  bannerList(data){
    let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/list/banner`, payload)
  },
  getLandingImage(data){
    return api.execute(`get`,`/image/landing/`+data)
  }
}
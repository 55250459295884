<template>
    <div style="overflow: auto;">
      <!-- <b-button @click="toggleBusy">Toggle Busy State</b-button> -->
      <b-table class="text-center" :items="serData" :busy="isBusy" :fields="serFields"  :per-page="perPage"
      stacked="md"
        :current-page="currentPage"  outlined show-empty>
        <template #cell(_id)="data">
          <b style="cursor: pointer;" class="text-primary" @click.prevent="editTable(data)" title="Service provider - Service name" >
            {{sentenceCase(data.item.sprovidername + ' - '  + data.item.sname) }} </b>
        </template>
        <template #cell(category)="data">
            <span>{{ filterCategory(data.item.category) }}</span>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
       <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        class="mt-2"
      ></b-pagination>
  
      <!-- <service-info />  -->
    </div>
  </template>
  
  <script>
    import ServiceInfo from './ServiceInfo.vue'
    import DashboardApi from '../../../services/DashboardService'
    import NetworkTypeService from '../../../services/NetworkTypeService'
    import config from '../../../services/globalLit'
  
    export default {
      components: {
        ServiceInfo
      },
      mounted() {
        this.init()
        // this.getNetworkType()
      },
      data() {
        return {
          data: "",
          showModal: false,
          isBusy: false,
          perPage: 10,
          currentPage: 1,
          rows: 1,
          serData: [],
          serFields: [],
          categoryArray: config.categorylist,
          dateRange: null
        }
      },
      created(){
        this.$root.$on('date-range-updated', this.onDateRangeUpdated)
      },
      beforeDestroy(){
        this.$root.$off('date-range-updated', this.onDateRangeUpdated)
      },
      methods: {
        async init(txt) {
          let payload = {}

          if(txt){
            payload.sdate = this.dateRange.startDate
            payload.edate = this.dateRange.endDate
          }
          
          let response = await DashboardApi.networkServices(payload)
         
          if(response.result) {
            this.serData = response.data;
            if(this.serData.length > 0){
            let filterdata = this.serData.slice(0, 1);
            filterdata  = filterdata.map(({ sname, sprovidername, ...rest }) => rest);
            this.serFields = Object.keys(filterdata[0]).map(e => ({ key: e, label: e === '_id' ? 'Service' : this.sentenceCase(e),thClass: 'custom-header'  }));
            }
            this.rows = this.serData.length;
          }
        },
        onDateRangeUpdated(dateRange){
          this.dateRange = dateRange
          this.init('dateRange')
        },
        toggleBusy() {
          this.isBusy = !this.isBusy
        },
        editTable(data) {
        // this.data = Object.assign({}, data.item);
        // this.showModal = true;
        // this.$root.$emit("openTrx", Object.assign({}, data));
        // this.$bvModal.show("openServiceInfo");
        this.$root.$emit("showtrxforlist",data);
        this.$bvModal.show("openSerList");
      },
         myRowClickHandler(record, index) {
          // 'record' will be the row data from items
          // `index` will be the visible row number (available in the v-model 'shownItems')
          log(record); // This will be the item data for the row
        },
        log(record) {
          console.log(record)
        },
        sentenceCase(str) {
          if (typeof str !== 'string') return str
          return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
        },
        async getNetworkType() {
          let response = await NetworkTypeService.getAllNetworkType();
          let networkTypeArray = [];
          for (const [key, value] of Object.entries(response.data)) {
            networkTypeArray.push({ id: key, name: value });
          }
          // this.serFields = [ ...[{
          //   key: "_id",
          //   label: "Services",
          //   thStyle: { color: "#000", "font-weight": "bold" },
          // }], ...networkTypeArray.map(e => {return {"key": e.name, "label": this.sentenceCase(e.name)}})]
         },
         filterCategory(category){
          if(this.categoryArray.filter(e => e.id == category)){
            return this.categoryArray.filter(e => e.id == category)[0].name
          }
         }
      }
    }
  </script>
  <style>
  .text-blue {
    color: #1c75bc;
  }
  .text-red {
    color: #f15a29;
    font-weight: lighter;
  }
  .table .bTableThStyle {
    background-color: #1c75bc !important;
    /* max-width: '100px' !important; */
    text-overflow: ellipsis !important;
  }
  .table > tbody > tr > td {
    max-width: 350px !important;
    overflow: auto;
    text-overflow: ellipsis;
  }
  .custom-header{
    order: 2;
    max-width: 16em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .custom-header:hover {
    max-width: auto;
    text-overflow: clip;
    white-space: normal;
    }
  </style>
<template>
    <div>
      <b-modal id="openServiceInfo" ok-only title="Ad Details" size="xl">
        <b-card>
          <h2>
            {{ mainObject.spName + " - " + mainObject.sName }}
  
            <b-badge pill variant="success">{{ mainObject.category }}</b-badge>
          </h2>
        </b-card>
        <div class="row" style="min-height: 40vh;">
          <div class="col-6">
            <b-row>
              <b-col>
                <b-card title="Home Page">
                  <!-- <b-img thumbnail fluid :src="mainObject.banner" alt="Banner" ></b-img> -->
                  <b-img
                    thumbnail
                    fluid
                    :src="getImageBanner(mainObject._id)"
                    alt="Banner"
                    @click="openImageModal(mainObject._id,'banner')"
                    @error="$event.target.src=(`../no_image.png`);"
                  ></b-img>
                </b-card>
              </b-col>
              <b-col>
                <b-card title="Advertisement">
                  <!-- <b-img thumbnail fluid :src="mainObject.ad" alt="Ad"></b-img> -->
                  <b-img thumbnail fluid :src="getImageAdd(mainObject._id)" alt="Ad" @click="openImageModal(mainObject._id,'add')" @error="$event.target.src=(`../no_image.png`);"></b-img>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div class="col-6">
            <b-card title="Ad Details">
              <b-table :items="mainObject.adDetailsTable" thead-class="d-none">
              </b-table>
              <b-row class="mt-2">
                <b-col>
                 <span class="link_txt" @click="CopyShareUrl(public_service_url)">Click here to Copy Url</span> 
                </b-col>
                <b-col class="text-right">
                  <b-button variant="success" size="sm" @click="ExportTrx(mainObject._id)">Export PDF</b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
       
      
      <b-card title="Landings">
            <b-row>
                <b-col md="3" v-for="(image, index) in mainObject.landingImgUrl" :key="index">
                  <img :src="image" alt="landing" class="img_custom_style" @click="openImageModal(image, 'landing')" @error="$event.target.src=(`../no_image.png`);">
                </b-col>
            </b-row>
      </b-card> 
       
        <div class="row">
          <div>
            <b-card no-body>
              <b-tabs pills card>
                <b-tab title="Hosts" active
                  ><b-card-text>
                    <li v-for="item in mainObject.hosts" :key="item.key">
                      {{ item }}
                    </li>
                  </b-card-text>
                </b-tab>
                <b-tab title="Urls"
                  >
                  <b-card-text>
                    <li v-for="item in mainObject.urlChain" :key="item.key">
                      {{ item }}
                    </li>
                  </b-card-text>
                  </b-tab
                >
                 <b-tab title="Logs"
                  >
                  <b-card-text>
                    <li v-for="item in mainObject.logs" :key="item.key">
                      {{ item }}
                    </li> 
                  </b-card-text>
                  </b-tab>
                <b-tab title="Comments"
                  >
                  <b-card-text>
                    <!-- <b-row class="mt-2">
                      <b-col class="f-16 text-primary" style="cursor: pointer;">
                       <span @click="getComments(mainObject._id)"> Click Here to show all commnets </span>
                      </b-col>
                    </b-row> -->
                    <b-row>
                      <b-col>
                        <div class="col-md-3" v-if="load_commnet">
                            <div class="loader-box">
                              <div class="loader-15"></div>
                            </div>
                        </div>
                        <b-table
                          v-else
                          stacked="md"
                          :items="commentList"
                          :fields="comment_fields"
                          striped
                          thead-class="d-none"
                          v-model="currentComment"
                        
                        >
                        <template #cell(_id)="row">
                            {{ row.item.comment }} – 
                            <span class="f-12 text-muted">Commented by </span>
                            <span class="text-primary" v-if="row.item.role == 'manager'">Manager</span>
                            <span class="text-primary" v-else>{{ row.item.username?row.item.username:row.item.useremail }}</span>
                        </template>
                        <template #cell(show_details)="row">
                    
                          <button
                            v-if="row.item.useremail === userinfo.email || userinfo.role === 'manager'"
                            class="btn btn-outline-info btn-xs mr-2"
                            @click="toggleDetails(row)">
                            {{ row.detailsShowing ? "Cancel" : "Edit" }}
                          </button>
                          <button
                            class="btn btn-outline-danger btn-xs mr-2"
                            v-if="row.item.useremail === userinfo.email || userinfo.role === 'manager'"
                            @click="DeleteComment(row.item)" variant='danger'>
                            Delete
                          </button>
                        </template>
                        <template #row-details="row">
                          <b-card bg-variant="light">
                            <b-form-textarea
                                id="commnets"
                                placeholder="Comment..."
                                rows="1"
                                max-rows="5"
                                v-model="row.item.comment"
                                >
                                </b-form-textarea>
                            <b-button size="sm" @click="editComment(row.item)" variant='info' class="mt-2">
                              Edit
                            </b-button>
                          </b-card>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                      <b-form-textarea
                      id="commnets"
                      placeholder="Comment..."
                      rows="3"
                      max-rows="6"
                      v-model="comment_text"
                      >
                      </b-form-textarea>
                    </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <b-button variant="success" @click.prevent="AddCommnet(mainObject._id)">Add Comment</b-button>
                      </b-col>
                    </b-row>

                  </b-card-text>
                  </b-tab
                >
                <b-tab title="Share"
                  >
                  <b-row>
                    <b-col cols="12">
                      <textarea class="col-md-12" name="" id="" rows="20" :value="mailToLink(public_service_url)"></textarea>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="link_txt" @click="CopyShareUrl(mailToLink(public_service_url))">Click here to copy...</b-col>
                    <b-col class="text-right">
                      <a :href="SendMail(mailToLink(public_service_url))">Share...</a> </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  import TranscationService from "../../../services/TranscationService";
  import NetworkTypeService from '../../../services/NetworkTypeService'
  import config from "../../../services/globalLit";
  
  export default {
  
    data() {
      return {
        comment_fields: ["_id",  "show_details"],
        currentComment: [],
        commentList:[],
        mainObject: {
          adDetailsTable: [],
        },
      
        showModal: "",
  
        tableKeys: {
          adUrl: "Ad URL",
          bannerFoundUrl: "Banner Found URL",
          finalUrl: "Final URL",
          netType: "Network|OP", //1: wifi 2: op1 3: op2
          channel: "Channel",
  
          paymentInfo: "Payment Info",
          pricePointInfo: "Pricepoint Info",
          createdAt: "Date",
        },
        fields: ["key", "value"],
        adDetailsTable: [{ key: "action", value: "action" }],
        modalimageurl: "",
        show_all_commnets: false,
        comment_text: "",
        public_service_url:'',
        userinfo:{},
        load_commnet:false,
        networkTypeArray : []
      };
    },
    mounted() {
      this.$root.$on("openTrx", (data) => {
        this.mainObject = {
          adDetailsTable: [],
        };
        this.data = data.item;
        this.init();
      });
    },
    beforeDestroy() {
      console.log('distroyed openTrx')
      this.$root.$off("openTrx");
    },
    methods: {
      async editComment(data){
        this.load_commnet = true;
        let payload = {'cid':data._id, 'updateObj':{comment:data.comment}}
        let response = await TranscationService.editComment(payload);
        this.getComments(data.trxid);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        this.load_commnet = false;
      },
     async DeleteComment(data){
      this.load_commnet = true;
      var payload = {'cid':data._id}
      if(confirm("Are you sure you want to delete this comment?")){
        let response = await TranscationService.deleteComment(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        this.getComments(data.trxid);
      }
      this.load_commnet = false;
      },
      async getComments(id){
        this.load_commnet = true;
        let response = await TranscationService.getComment(id);
        if(response.result){
          this.commentList = response.data
        }
        this.load_commnet = false;
      },
      async AddCommnet(id){
        this.load_commnet = true;
        if(this.comment_text == ""){
          this.$toasted.show("Please enter comment", {theme: 'outline',position: "bottom-center", type: "error", duration: 2000});
          return;
        }
        var payload = {"trxid":id,"comment":this.comment_text}
        let response = await TranscationService.addCommnet(payload);
        this.getComments(id);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        this.comment_text = "";
        this.load_commnet = false;
      },
      CopyShareUrl(text){
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = text;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show("Copied", {theme: 'outline',position: "bottom-center", type: "success", duration: 2000});
      },
     async ExportTrx(id){
        let url = config.exportTrnx+`${id}`
        const a = document.createElement('a');
        a.href = url;
        a.download = 'transaction.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      openImageModal(data, type) {
        this.modalimageurl = "";
        switch(type){
          case 'banner':
            this.modalimageurl = this.getImageBanner(data)
            break;
          case 'add':
            this.modalimageurl = this.getImageAdd(data)
            break;
          case 'landing':
            this.modalimageurl = data
            break;
        }
        // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
        const popupWindow = window.open('', '_blank', `width=${screen.width},height=${screen.height}`);
        popupWindow.document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
        popupWindow.document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
          popupWindow.close();
        }});
      },
      getImageLanding(id){
        return config.getLandingImages+`/${id}`
      },
      getImageBanner(id){
        return config.getBannerImages+`/${id}`
      },
      getImageAdd(id){
        return config.getAddImages+`/${id}`
      },
      arrayBufferToBase64(buffer) {
        var binary = "";
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return binary;
      },
      async init() {
        console.log("init call")
        await this.getNetworkType();
        let payload = {
          sid: this.data._id,
          trxid: this.data.trxid
        };
        let response = await TranscationService.trxDetail(payload);
        if (response.result) {
          
          this.mainObject = response.data;
          this.mainObject.adDetailsTable = []
          
          this.mainObject.category = config.categorylist[this.mainObject.category].name;
         
          let trxKey = Object.keys(this.mainObject)
  
          for (let i = 0; i < trxKey.length; i++) {
            if (this.tableKeys[trxKey[i]]) {
              let rec = {
                key: this.tableKeys[trxKey[i]],
                value: this.mainObject[trxKey[i]],
              };
              if (rec.key == "Channel") {
                rec.value = config.channelTypeArray.find(
                  (e) => e.id == rec.value
                ).name;
              }
              if (rec.key == "Network|OP") {
                rec.value = this.networkTypeArray.find(
                  (e) => e.id == rec.value
                ).name;
              }
              this.mainObject.adDetailsTable.push(rec);
            }
          }
        }
        this.getComments(this.mainObject._id)
        let host = window.location.host;
        let protocol = window.location.protocol;
        this.public_service_url = protocol + '//' +host+'/servicedetail/'+this.mainObject._id
        this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
      },
      async getNetworkType() {
        let response = await NetworkTypeService.getAllNetworkType();
        let networkTypeArray = [];
        for (const [key, value] of Object.entries(response.data)) {
          networkTypeArray.push({ id: key, name: value });
        }
        this.networkTypeArray = networkTypeArray;
      },
      toggleDetails(row) {
        if (row.detailsShowing) {
          row.toggleDetails()
        } else {
          this.currentComment.forEach(item => {
            this.$set(item, '_showDetails', false)
          })
          this.$nextTick(() => {
            row.toggleDetails()
          })
        }
      },
      mailToLink(url) {
        let body = `
Dear Marketing Team,

  I am writing to bring to your attention an issue related to a recent marketing that was conducted for the brand.

  As a concerned party, I would like to request that you review this matter and take the necessary corrective action to ensure that your advertising and
  marketing practices are fully compliant with all relevant regulatory requirements.

  For your reference, here is the link to the advertising page that was used during the test:
  <a href="${url}">${url}</a>.

  If you require further information or clarification on this matter, please do not hesitate to reach out to me.

  Thank you for your prompt attention to this issue.

Sincerely,
${this.userinfo.username}
${this.userinfo.email}
`.replace(/<[^>]*>/g, ''); 
        return body
      },
      SendMail(data){
        let from = this.userinfo.email
        let subject = 'Concern regarding recent marketing'
        let body = data 
        let mailtoLink = 'mailto:?subject='+encodeURIComponent(subject)+'&body='+encodeURIComponent(body)+'&from='+from
          // Set the Content-Type header to "text/html"
          mailtoLink += '&Content-Type=text/html';
          // Add the "from" parameter to the mailto link if the "from" address is specified
          if (from) {
            mailtoLink += '&from=' + encodeURIComponent(from);
          }
        return mailtoLink
      }
    },
  };
  </script>
  
  <style scoped>
  .hidden_header {
    display: none;
  }
  div {
    margin: auto 0;
    width: 100%;
  }
  .img_custom_style {
  min-height: 25px;
  min-width: 15px;
  max-height: 150%;
  max-width: 100%;
  border-radius: 10px 10px 10px 10px;
}
.img_custom_style:hover {
  cursor: pointer;
  border: 2px solid blue;
}
  </style>
<template>
    <div>
      <b-row>
        <b-col lg="12">
          <div>
            <b-card title="Services">
              <services-table />
            </b-card>
          </div>
        </b-col>
        <b-col lg="12">
          <div>
            <b-card title="Operator/ WIFI Performance">
              <pie-chart class="pie-chart-height"
                v-if="loaded"
                :chartData="pieChartData"
                :chartLabels="pieChartLabel"
                @filerService="filterService"
              />
            </b-card>
            <service-list></service-list>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" class="m-2 mt-3">
          <div>
            <b-card title="Service Category" type="default" style="background-color: #ffffff;color:#000" >
              <!-- <b-row align-v="center" slot="header">
                <b-col>
                  <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                  <h5 class="h3 text-white mb-0">Service Category</h5>
                </b-col>
                <b-col>
                  <b-nav class="nav-pills justify-content-end"> -->
                    <!-- <b-nav-item
                         class="mr-2 mr-md-0"
                         :active="bigLineChart.activeIndex === 0"
                         link-classes="py-2 px-3"
                         @click.prevent="initBigChart(0)">
                        <span class="d-none d-md-block">Month</span>
                        <span class="d-md-none">M</span>
                    </b-nav-item>
                     <b-nav-item
                      link-classes="py-2 px-3"
                      :active="bigLineChart.activeIndex === 1"
                      @click.prevent="initBigChart(1)"
                    >
                    <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </b-nav-item> -->
                  <!-- </b-nav>
                </b-col> -->
              <!-- </b-row> -->
              <line-chart class="p-4"
                :height="350"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :extra-options="bigLineChart.extraOptions"
              >
              </line-chart>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col lg="12" class="m-2">
          <div>
            <b-card title="Card Title">
              <bar-chart />
            </b-card>
          </div>
        </b-col>
      </b-row> -->
    </div>
  </template>
  <script>
  import { FadeTransition } from "vue2-transitions";
  import LineChart from "../../components/Charts/LineChart";
  import BarChart from "../../components/Charts/BarChart";
  import PieChart from "../../components/Charts/PieChart";
  import * as chartConfigs from "@/components/Charts/config";
  import ServicesTable from "./../Dashboard/ServicesTable.vue";
  import globalConfig from "../../../services/globalLit";
  import Pie from "../../components/Charts/Pie.vue";
  import DashboardService from "../../../services/DashboardService";
  import NetworkTypeService from '../../../services/NetworkTypeService'
  import ServiceList from "../../views/ServicesList.vue";
  
  export default {
    components: {
      FadeTransition,
      LineChart,
      BarChart,
      PieChart,
      ServicesTable,
      Pie,
      ServiceList
    },
    methods: {
      filterService(data) {
        let payload = {};
        payload.netType = data.id
        this.$root.$emit("showtrxforlist",payload);
        this.$bvModal.show("openSerList");
        return;
      },
      getArrayData(labelArray, recDataObj) {
        let dataArray = [];
        
        for(let i=0; i<labelArray.length; i++) {
            
            let isRecordFound = recDataObj ? recDataObj.find(e=> e.date.split("T")[0] == labelArray[i]) : false
            if(isRecordFound) {
              dataArray.push(isRecordFound.total)
            } else {
              dataArray.push(0)
            }
        }
        return dataArray;
      },
      async initBigChart(index) {
        // await this.getNetworkType();
        let today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        let label = [today.toISOString().split("T")[0]];
        for (let i = 0; i < 8; i++) {
          label.push(
            new Date(today.setDate(today.getDate() - 1))
              .toISOString()
              .split("T")[0]
          );
        }
        label = label.reverse()
  
        let categoryPayload = {};
        let categoryStatRes = await DashboardService.categoryStat(
          categoryPayload
        );
        if (categoryStatRes.result) {
          console.log(categoryStatRes.data);
  
          let allData = categoryStatRes.data;
          let allCatKey = Object.keys(allData);
  
          console.log(allCatKey);
          let datasets = [];
          for (let i = 0; i < globalConfig.categorylist.length; i++) {
            let rec = {};
            let catData = globalConfig.categorylist[i]
            rec.label = catData.name ;
            rec.backgroundColor = catData.color
            rec.borderColor = catData.color
            rec.data = this.getArrayData(label, allData[catData["id"]]);
            datasets.push(rec);
          }
          console.log(datasets);
          // 1: [day1, day2]
          let chartData = {
          datasets: datasets,
          labels: label,
        };
        this.bigLineChart.chartData = chartData;
        }
       
  
        let payload = {};
        let response = await DashboardService.operatorPer(payload);
  
        if (response.result) {
          this.pieChartData = [];
          this.pieChartLabel = [];
          const firstTenData = response.data.slice(0, 7)
          this.pieChartData = firstTenData.map(e=> e.total)
          this.pieChartLabel = firstTenData.map(e=> e._id)
          // this.networkTypeArray.map(
          //   (e) =>{
          //   if(response.data.find((i) => i._id == e.name)){
          //     this.pieChartData.push(
          //       response.data.find((i) => i._id == e.name).total
          //     )
          //   }
          // }
          // );
          this.loaded = true;
        }
      },
      async getNetworkType() {
        let response = await NetworkTypeService.getAllNetworkType();
        let networkTypeArray = [];
        for (const [key, value] of Object.entries(response.data)) {
          networkTypeArray.push({ id: key, name: value });
        }
        this.networkTypeArray = networkTypeArray;
        this.pieChartLabel = this.networkTypeArray.map((e) => e.name);
    },
    },
    created() {},
    mounted() {
      this.initBigChart(0);
    },
    data() {
      return {
        networkTypeArray:[],
        pieChartLabel:'',
        lineChartLabel: [],
        loaded: false,
        pieChartData: [0, 0, 0, 0],
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60],
            [0, 20, 5, 25, 10, 30, 15, 40, 40],
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: "Performance",
                borderColor: "red",
                data: [0, 20, 10, 30, 15, 40, 20, 60],
              },
              {
                label: "test",
                data: [3, 1, 44, 44, 12, 312, 123, 4],
              },
              {
                label: "t",
                borderColor: "",
                data: [0, 1, 50, 30, 15, 40, 20, 60],
              },
            ],
            labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        lineChart: {
          data: {
            labels: [
              "9:00AM",
              "12:00AM",
              "3:00PM",
              "6:00PM",
              "9:00PM",
              "12:00PM",
              "3:00AM",
              "6:00AM",
            ],
            series: [
              [287, 385, 490, 492, 554, 586, 698, 695],
              [67, 152, 143, 240, 287, 335, 435, 437],
              [23, 113, 67, 108, 190, 239, 307, 308],
            ],
          },
          options: {
            low: 0,
            high: 800,
            showArea: false,
            height: "245px",
            axisX: {
              showGrid: false,
            },
            lineSmooth: true,
            showLine: true,
            showPoint: true,
            fullWidth: true,
            chartPadding: {
              right: 50,
            },
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {
                axisX: {
                  labelInterpolationFnc(value) {
                    return value[0];
                  },
                },
              },
            ],
          ],
        },
      };
    },
  };
  </script>
<style>
.pie-chart-height{
  height: 50vh !important;
}
@media (max-width: 767px) {
  .pie-chart-height{
    height: 90vh !important;
  }
}
</style>
  
import { Pie } from 'vue-chartjs'
import config from '../../../services/globalLit'
import NetworkTypeService from '../../../services/NetworkTypeService'
export default {
  extends: Pie,
  props: {
    chartData: {
      type: Array | Object,
      required: true
    },
   
    chartLabels: {
      type: Array,
      required: true
    },
    titles: {
      type: String,
      required: false
    }
  },
  data: function(){
    return {
      networkTypeArray : [],
      maxLabelsToShow : 10
    }
  },
  watch : {
    chartLabels : function(){
      console.log('label render')
      this.fillChart()
    },
    chartData :  function(){
      console.log('data render')
      this.fillChart()
    }
  },
  mounted () {
    console.log("mounterd chart")
    console.log(this.chartData)
      this.fillChart()
  },
  methods: {
    genColor: function(){
     let bgcolor = this.chartLabels.map(()=>{
       return '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6)
     })
      return bgcolor
    },
    fillChart: async function () {
      // await this.getNetworkType()
      this.renderChart({
        labels: this.chartLabels,
        datasets: [
          {
            backgroundColor: ["#4679A7", "#84AEDC", "#B8CDE8", "#5591C7", "#8BB2DD", "#A6C6E4", "#639CC3", "#9FB9D8", "#77A1CA", "#88A8CE"],
            data: this.chartData,
          }
        ]
      }, {
        legend: {
          display: true,
          labels: {
            fontColor:'#000'
          }
        },
        plugins: {
          labels: {
            render: function (args) {
              // return args.percentage + "%";
              return " ";
              // return args.label + " : " + args.percentage + "%";
            },
            fontColor: '#000', // Set the font color for each segment
            precision: 0, // Number of decimal places to round the percentage value to
            fontSize: 20,
            fontWeight: 'bold',
            position: 'inside',
            textMargin: 15
          }
        },
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: {
            label:function (tooltipItem, data) {
              let dataset = data.datasets[tooltipItem.datasetIndex];
              let currentValue = dataset.data[tooltipItem.index];
              let total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
              let percentage = ((currentValue / total) * 100).toFixed(0);
              let label = data.labels[tooltipItem.index]; // Get the label name
              return label + ': ' + currentValue + ' (' + percentage + '%)';
            }
          }
        },
        onClick: (event, chartElements) => {
          if (chartElements && chartElements.length > 0) {
            var label = config.sentenceCase(chartElements[0]._model.label);
            let data  = this.networkTypeArray.filter((e) => config.sentenceCase(e.name) == label)
            // desabled transaction modal for now not required
            // this.$emit('filerService', data[0])
          }
        }
      })
    },
    // async getNetworkType() {
    //   let response = await NetworkTypeService.getAllNetworkType();
    //   var networkTypeArray = [];
    //   for (const [key, value] of Object.entries(response.data)) {
    //     networkTypeArray.push({ id: key, name: value });
    //   }
    //   this.networkTypeArray = networkTypeArray;
    //   // Check if the number of labels exceeds the maximum limit
    //   if (this.networkTypeArray.length > this.maxLabelsToShow) {
    //     const slicedArray = this.networkTypeArray.slice(0, this.maxLabelsToShow); // Slice the array to get the limited labels
    //     const remainingCount = this.networkTypeArray.length - this.maxLabelsToShow; // Calculate the number of remaining labels
    //     const otherLabel = { id: 'other', name: `Other (${remainingCount})` }; // Create an "Other" label with the count of remaining labels
    //     this.networkTypeArray = [...slicedArray, otherLabel]; // Combine the sliced array and the "Other" label
    //   }
    //  }
  }
}
import api from './api'
import globalLit from './globalLit'
export default { 
  getlist(data) {
    let payload = globalLit.checkCountry(data)

    return api.execute(`post`,`/dailies/list`, payload)
  },
  getTrxs(data) {
    let payload = globalLit.checkCountry(data);

    return api.execute(`post`,`/dailies/trxs`, payload)
  },
  
}
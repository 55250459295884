<template>
  <Pie
    v-if="loaded"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Pie } from "vue-chartjs";
import globalConfig from "../../../services/globalLit";
import DashboardService from "../../../services/DashboardService";
import NetworkTypeService from '../../../services/NetworkTypeService'
import 'chartjs-plugin-labels';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

// ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    await this.init();
  },
  data() {
    return {
      networkTypeArray:[],
      loaded: false,
      chartData: {
        labels: '',
        datasets: [
          {
            backgroundColor: ["#5D6D7E", "#DC7633", "#F4D03F", "#1ABC9C"],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    async init() {
      await this.getNetworkType();
      let payload = {};
      let response = await DashboardService.operatorPer(payload);
      if (response.result) {
        this.chartData.datasets[0]["data"] = this.networkTypeArray.map(
          (e) => response.data.find((i) => i._id == e.name).total
        );
      }
      console.log(this.chartData);
      this.loaded = true;
    },
    async getNetworkType() {
        let response = await NetworkTypeService.getAllNetworkType();
        let networkTypeArray = [];
        for (const [key, value] of Object.entries(response.data)) {
          networkTypeArray.push({ id: key, name: value });
        }
        this.networkTypeArray = networkTypeArray;
        this.chartData.labels = this.networkTypeArray.map((e) => e.name);
    },
  },
};
</script>
